//import axios from 'axios';
import api from './api.js'
//import authHeader from './auth-header';

//const urlApi = "https://127.0.0.1:8000/api/";
const urlApi = "https://dev.okbt.fr/api/";

class UserService {
getLastVersion() {
    return api.get(urlApi + 'lastPwa');
}

getUserMe() {
    return api.get(urlApi + 'me');
}

getInterventions(filters) {
    return api.get(urlApi + 'interventions' + filters);
}

getIntervention(id) {
    return api.get(urlApi + 'interventions/' + id);
  }

postIntervention(data) {
return api.post(urlApi + 'interventions', data);
}

patchIntervention(id, data) {
return api.patch(urlApi + 'interventions/' + id, data);
}

getCollaborateursForm() {
    return api.get(urlApi + 'collaborateurs?q=collaborateurForm');
}

getClientsForm() {
    return api.get(urlApi + 'clients?q=clientForm');
}

getContactsForm() {
    return api.get(urlApi + 'contacts?q=contactForm');
}

getEquipmentsForm() {
    return api.get(urlApi + 'collaborateur_equipments?q=equipmentFavorisForm');
}

getJournees(filters) {
    return api.get(urlApi + 'journees' + filters);
}

getJournee(id) {
    return api.get(urlApi + 'journees/' + id);
  }

postJournee(data) {
return api.post(urlApi + 'journees', data);
}

getDocuments(filters) {
    return api.get(urlApi + 'fichiers' + filters);
}

}

export default new UserService();
